import React from "react";
import { Link } from 'gatsby';
import Header from "../Layout/Header/Header";
import {Container, Row} from '../UI/Grid/Grid';
import { ButtonCTA } from '../UI/Button/Button';
import SEO from "../seo";
import * as classes from './Thanks.module.scss';

const Thanks = (props) => (
  <div className={classes.Background}>
    <Header />
    <SEO title="شكرا" />
    <Container className={classes.ContentContainer}>
      <Row className={classes.Content}>
        <h3>
        نشكركم لتواصلكم معنا سيصلكم رد منا قريبا!
        </h3>
        <ButtonCTA className={`${classes.CTA} mt-5`}>
          <Link to="/#home">
          الصفحة الرئيسية
          </Link>
        </ButtonCTA>
      </Row>
    </Container>
  </div>
)

export default Thanks
