import React from "react";
import FormConfirm from '../components/Thanks/Thanks'
import SEO from "../components/seo";

import '../assets/global_styles/styles.scss';

const Thanks = (props) => (
  <div className="bg-primary">
    <SEO title="Home" />
    <FormConfirm />
  </div>
)

export default Thanks
